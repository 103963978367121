; ((w, $) => {
   'use strict'

   const fincorpChangeStylingDropdownForm = () => {

      $(document).on("click", '.selected-item', function (e) {
         let currentSelectedItem = $(this),
            currentAllItems = currentSelectedItem.next(".all-items");

         $('.all-items').not(currentAllItems).addClass("all-items-hide");
         $('.selected-item').not(currentSelectedItem).removeClass("arrowanim");

         currentAllItems.toggleClass("all-items-hide");
         currentSelectedItem.toggleClass("arrowanim");

         e.stopPropagation();
      });

      $(document).on("click", ".item", function () {
         let that = $(this);
         let selectedOptionText = that.html(),
            selectedOptionVal = that.data('value');
         $('.selected-item').html(selectedOptionText).removeClass("arrowanim").addClass("active-color");
         $('.all-items').addClass("all-items-hide");
         that.parents('.nf-field-element').find('select').val(selectedOptionVal).change(); 
         $('body').data('select', selectedOptionVal);
         $('body').attr('data-select', selectedOptionVal);
      });

      $(document).on("click", function () {
         $(".all-items").addClass("all-items-hide");
         $(".selected-item").removeClass("arrowanim");
      });
   }

   $(document).on('nfFormReady', function () {
      fincorpChangeStylingDropdownForm();
   });

})(window, jQuery)